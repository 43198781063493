import React from 'react';
import { LoadingSpinner } from '@neo4j-ndl/react';
import { BoltIconSolid } from '@neo4j-ndl/react/icons';
import { NeoDashboardHeaderLogo } from '../header/DashboardHeaderLogo';

export const NeoDashboardPlaceholder = () => {
  return (
    <>
      <div className='n-w-full n-h-full n-overflow-y-scroll n-flex n-flex-row'>
        <div className='n-flex-1 n-relative n-z-0  n-scroll-smooth n-w-full'>
          <div className='n-absolute n-inset-0 page-spacing'>
            <div className='page-spacing-overflow'>
              <div className='n-absolute n-w-full n-h-full'>
                <LoadingSpinner size='large' className='centered' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NeoDashboardPlaceholder;
